import React from "react";
import Typed from "react-typed";
import { Typography, Row, Col, Avatar, Image } from "antd";
import logo from "../img/avataaars.svg";
import "./about.css";
import { AiFillHeart } from "react-icons/ai";
import { TypeAnimation } from "react-type-animation";

const { Title } = Typography;

const About = (props) => {
  let iconStyle = { color: "#CC9A48", fontSize: "20px", verticalAlign: "sub" };

  //const About = (props) => {
  //If needed you can duplicate the state and effect so the events are separated

  const typed = (strings) => {
    return (
      <div>
        <Typed
          strings={strings}
          typeSpeed={40}
          backSpeed={50}
          loop
          //style={{display: "flex", flexWrap: "wrap"}}
        />
      </div>
    );
  };

  return (
    <div className="lead">
      <Row justify="center">
        <Col style={{ textAlign: "center" }}>
          <Title>
            {" "}
            Hi! I'm <span className="my-name">Lucian</span>!
          </Title>
          <Avatar
            className="avatar-image"
            src={<Image src={logo} preview={false} />}
            //style={{ minWidth: "20em", minHeight: "20em" }}
          />

        </Col>
      </Row>
      <Row justify="center">
        <Col style={{ textAlign: "center" }}>
        <Title>
            I'm a <br />
            <span className="job-title-typed">
              {typed([
                "Senior Technical Writer",
                "Web Developer",
                "Product Manager",
              ])}
            </span>
          </Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col flex={"auto"} style={{ textAlign: "center" }}>
          <code style={{ fontSize: "large", color: "#dfdfdf" }}>
            I <AiFillHeart style={iconStyle} /> Asciidoc | static site
            generators | Docs-as-Code | DocOps | JavaScript | React | NodeJS |
            Bash | Git | Unix
          </code>
        </Col>
      </Row>
    </div>
  );
};
//export default withRouter(FCOMP)
export default About;
