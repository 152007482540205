import React, { useState, useEffect } from "react";
import { Timeline, Row, Col, Avatar, Image, Divider, Tooltip } from "antd";
import './experience.css'

const items = [
  {
    color: "#CC9A48",
    children: (
      <>
        <div className="job-title">
          Senior Technical Writer at Signify, Eindhoven
        </div>
        <div className="job-duration">March 2019 — Present</div>
        <div className="job-description">
          <ul>
            <li>Wrote technical documentation for IoT lighting systems</li>
            <li>Lead the digital transformation process of technical documentation</li>
            <li>Lead the implementation of SCRUM & Agile practices</li>
            <li>Developed the Interact UI for technical documentation</li>
            <li>Developed several tools for automatic migration from PDF to Asciidoc</li>
          </ul>
        </div>
      </>
    ),
  },
  {
    color: "#CC9A48",
    children: (
      <>
        <div className="job-title">
          Senior Technical Writer & Chapter Lead at Backbase, Amsterdam
        </div>
        <div className="job-duration">September 2016 — February 2019</div>
        <div className="job-description">
          <ul>
            <li>Wrote software technical documentation, APIs & learning paths</li>
            <li>Lead a team of Technical Writers</li>
            <li>Developed tools in Bash to automate tasks</li>
            <li>Set up pipelines in Jenkins for documentation builds</li>
            <li>Involved in the recruitment process of Technical Writers</li>
            <li>Held training sessions & workshops for Technical Writers</li>
          </ul>
        </div>
      </>
    ),
  },
  {
    color: "#CC9A48",
    children: (
        <>
          <div className="job-title">
          Technical Writer & Product Specialist at (N-able), Bucharest
          </div>
          <div className="job-duration">October 2015 — June 2016</div>
          <div className="job-description">
            <ul>
              <li>Wrote software technical documentation, datasheets & blog posts & announcements</li>
              <li>Managed the strategy, content & metrics for the company blog</li>
              <li>Managed external technical communication via forums</li>
            </ul>
          </div>
        </>
      ),
  },
  {
    color: "#CC9A48",
    children: (
        <>
          <div className="job-title">
          PR Specialist at Spiru Haret University, Bucharest
          </div>
          <div className="job-duration">February 2015 — August 2015</div>
          <div className="job-description">
            <ul>
              <li>Project manager on two re-design web projects</li>
              <li>Wrote press releases, adverts & web content</li>
              <li>Presented the educational portfolio to large groups</li>
              <li>Coordinated social media activities</li>
            </ul>
          </div>
        </>
      ),
  },
  {
    color: "#CC9A48",
    children: (
        <>
          <div className="job-title">
          Content specialist at Bitdefender, Bucharest
          </div>
          <div className="job-duration">December 2013 — December 2014</div>
          <div className="job-description">
            <ul>
              <li>Wrote industry news & blog posts on security topics</li>
              <li>Wrote press releases</li>
              <li>Covered requests for comments</li>
            </ul>
          </div>
        </>
      ),
  },
  {
    color: "#CC9A48",
    children: (
        <>
          <div className="job-title">
          QA Tester at Electronic Arts (EA), Bucharest
                    </div>
          <div className="job-duration">July 2012 — February 2013; September 2013 — November 2013</div>
          <div className="job-description">
            <ul>
              <li>Functional/compliance & persistence testing for various projects</li>
              <li>Automated the persistence testing process for one project</li>
              <li>Coordinated a small team of testers</li>
            </ul>
          </div>
        </>
      ),
  },
];

const Experience = (props) => {
  return (
    <Row justify="center">
      <Col offset={1}>
        <Timeline color="#CC9A48" items={items} />
      </Col>
    </Row>
  );
};
export default Experience;
