import React from "react";
import "./skills.css";
import { Typography, Row, Col, Avatar, Image, Divider, Tooltip } from "antd";
import { IoLogoReact, IoLogoHtml5, IoLogoCss3 } from "react-icons/io5";
import { IoLogoJavascript, IoLogoNodejs } from "react-icons/io";
import { TbApi } from "react-icons/tb"
import {
  SiGnubash,
  SiLinux,
  SiGit,
  SiAsciidoctor,
  SiMarkdown,
  SiJenkins,
} from "react-icons/si";
import { GiDuck } from "react-icons/gi";
import { FaAws } from "react-icons/fa";
import { GoFileCode } from "react-icons/go";
import { DiScrum } from "react-icons/di";
import { BsArrowRepeat, BsMarkdown } from "react-icons/bs";

const { Title, Paragraph } = Typography;
//const Skills = (props) => {
const Skills = (props) => {
  let iconStyle = {
    color: "#CC9A48",
    fontSize: "7em",
    marginRight: "20px",
  };

  //If needed you can duplicate the state and effect so the events are separated

  return (
    <>
<div style={{textAlign:"center", marginTop: "10px"}}>
          <Tooltip  title="JavaScript" color="#826343">
            <IoLogoJavascript style={iconStyle} />
          </Tooltip>
          <Tooltip title="React" color="#826343">
            <IoLogoReact style={iconStyle} />
          </Tooltip>
          <Tooltip title="NodeJS" color="#826343">
            <IoLogoNodejs style={iconStyle} />
          </Tooltip>
          <Tooltip title="AWS" color="#826343">
            <FaAws style={iconStyle} />
          </Tooltip>
          <Tooltip title="HTML" color="#826343">
            <IoLogoHtml5 style={iconStyle} />
          </Tooltip>
          <Tooltip title="CSS" color="#826343">
            <IoLogoCss3 style={iconStyle} />
          </Tooltip>
          <Tooltip title="Bash" color="#826343">
            <SiGnubash style={iconStyle} />
          </Tooltip>

          </div>
      <div style={{textAlign:"center", marginTop: "80px"}}>
          <Tooltip title="Asciidoc" color="#826343">
            <SiAsciidoctor style={iconStyle} /> 
          </Tooltip>
          <Tooltip title="Markdown" color="#826343">
          <BsMarkdown style={iconStyle} /> 
          </Tooltip>
          <Tooltip title="Docbook XML" color="#826343">
          <GiDuck style={iconStyle} /> 
          </Tooltip>
          <Tooltip title="Jenkins" color="#826343">
          <SiJenkins style={iconStyle} /> 
          </Tooltip>
          <Tooltip title=" REST APIs" color="#826343">
          <TbApi style={iconStyle} />
          </Tooltip>
          <Tooltip title="Git" color="#826343">
            <SiGit style={iconStyle} />
          </Tooltip>
          <Tooltip title="Linux" color="#826343">
            <SiLinux style={iconStyle} />
          </Tooltip>
      </div>
    </>
  );
};
//export default withRouter(FCOMP)
export default Skills;

/*
Skills
category:
    1. Programming
        JavaScript / React / NodeJS
        HTML / CSS
        Bash
        Linux
        CI / CD

        #DynamoDB
        #MySQL
        #Jenkins


    2. Technical Writing
        Asciidoc
        Markdown
        Docbook XML
        Docs-as-code
        DocOps

      <Col span={6}>
        <code style={{ fontSize: "2em" }} className="skill">
          <SiAsciidoctor style={iconStyle} /> Asciidoc
        </code>
        <br />
        <code style={{ fontSize: "2em" }} className="skill">
          <BsMarkdown style={iconStyle} /> Markdown
        </code>
        <br />
        <code style={{ fontSize: "2em" }} className="skill">
          <GiDuck style={iconStyle} /> Docbook XML
        </code>
        <br />
        <code style={{ fontSize: "2em" }} className="skill">
          <GoFileCode style={iconStyle} /> Docs-as-code
        </code>
        <br />
        <code style={{ fontSize: "2em" }} className="skill">
          <AiOutlineCode style={iconStyle} /> DocOps
        </code>
        <br />
        <code style={{ fontSize: "2em" }} className="skill">
          <SiJenkins style={iconStyle} /> CI / CD
        </code>
        <br />
        <code style={{ fontSize: "2em" }} className="skill">
          <TbCloudComputing style={iconStyle} /> REST APIs
        </code>
        <br />
        <code style={{ fontSize: "2em" }} className="skill">
          <BsArrowRepeat style={iconStyle} /> Agile
        </code>
        <br />
      </Col>
      <Col span={6}></Col>

*/
